import { defineStore } from "pinia";

const useLoadingStore = defineStore("loading", {
  state: () => ({
    loading: false
  }),
  actions: {
    setLoading(loading) {
      this.loading = loading;
    }
  }
});

export default useLoadingStore;
