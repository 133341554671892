import { defineStore } from "pinia";
import isEmail from "validator/lib/isEmail";
import usersApi from "../api/user";
import useLoadingStore from "./loading-store";

const useUserStore = defineStore("user", {
  state: () => ({
    shippingAddresses: [],
    billingAddresses: [],
    guest: false,
    guestType: null,
    loginErrors: {},
    registerErrors: {},
    cards: [],
    stripeV2Cards: [],
    checkoutDotComCards: [],
    credit: {},
    newCustomer: false,
    memberOfLoyaltyProgram: false,
    first_name: "",
    last_name: "",
    email: "",
    isInternal: false
  }),
  actions: {
    async login(details) {
      if (!details.email || !isEmail(details.email)) {
        this.loginErrors = {
          email: ["This doesn't look like a valid email"]
        };
        return;
      }

      this.loginErrors = {};
      const loadingStore = useLoadingStore();
      const { setLoading } = loadingStore;
      setLoading(true);
      const response = await usersApi.login(details);
      if (response.success) {
        this.loginErrors = {};
        analytics.track("Logged In", { situation: "Papier Website" });
        if (details.weddingAddOns) {
          const showWeddingAddOnsFromLogin = new CustomEvent(
            "showWeddingAddOnsFromLogin"
          );
          window.dispatchEvent(showWeddingAddOnsFromLogin);
          const closeLoginModal = new Event("closeLoginModal");
          window.dispatchEvent(closeLoginModal);
          setLoading(false);
        } else {
          window.location.reload();
        }
      } else {
        setLoading(false);
        this.loginErrors = { email: [response.data.error] };
      }
    },
    async register(details) {
      if (!details.email || !isEmail(details.email)) {
        this.registerErrors = {
          email: ["This doesn't look like a valid email"]
        };
        return;
      }

      this.registerErrors = {};
      const loadingStore = useLoadingStore();
      const { setLoading } = loadingStore;
      setLoading(true);
      const response = await usersApi.register(details);
      if (response.success) {
        this.registerErrors = {};
        analytics.track("Signed Up", {
          location: details.location,
          situation: "Papier Website"
        });
        if (details.weddingAddOns) {
          const showWeddingAddOnsFromLogin = new CustomEvent(
            "showWeddingAddOnsFromLogin"
          );
          window.dispatchEvent(showWeddingAddOnsFromLogin);
          const closeRegisterModal = new Event("closeRegisterModal");
          window.dispatchEvent(closeRegisterModal);
          setLoading(false);
        } else {
          window.location.reload();
        }
      } else {
        setLoading(false);
        this.registerErrors = response.data.errors;
      }
    }
  }
});

export default useUserStore;
