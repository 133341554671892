<template>
  <form @submit.prevent="submitForm">
    <div class="grid grid-cols-2 gap-3 mb-5">
      <text-input
        v-model="user.first_name"
        :errors="registerErrors['first_name']"
        required
        autocorrect="off"
        input-id="register-modal-first-name"
        :data-cy="`${signupLocation}-first-name`"
        :label="t('register-modal.form.first-name')"
        type="text"
        autocomplete="off" />
      <text-input
        v-model="user.last_name"
        :errors="registerErrors['last_name']"
        required
        autocorrect="off"
        input-id="register-modal-last-name"
        :data-cy="`${signupLocation}-last-name`"
        :label="t('register-modal.form.last-name')"
        type="text"
        autocomplete="off" />
    </div>
    <date-of-birth-input
      v-model="user.date_of_birth"
      :label="t('register-modal.form.date-of-birth')"
      :errors="registerErrors['date_of_birth']"
      class="mb-5" />
    <text-input
      v-model="user.email"
      class="mb-5"
      type="email"
      :errors="registerErrors['email']"
      required
      autocorrect="off"
      autocapitalize="off"
      input-id="register-modal-email"
      :data-cy="`${signupLocation}-email`"
      :label="t('register-modal.form.email')"
      inputmode="email"
      autocomplete="off" />
    <text-input
      v-model="user.password"
      class="mb-5"
      :errors="registerErrors['password']"
      required
      autocorrect="off"
      autocapitalize="off"
      input-id="register-modal-password"
      :data-cy="`${signupLocation}-password`"
      type="password"
      :label="t('register-modal.form.password')"
      autocomplete="off" />
    <checkbox-input
      v-if="!hideCheckbox"
      v-model:checked="user.newsletter"
      flex-alignment-class="items-start"
      input-id="register-modal-newsletter"
      :data-cy="`${signupLocation}-newsletter`"
      class="mb-5"
      :label="t('register-modal.form.newsletter.explanation')"
      @update:checked="triggerAnalyticsEvent" />
    <input
      type="submit"
      :value="t('register-modal.form.cta')"
      class="btn btn-primary btn-large btn-block mb-3"
      data-cy="signup" />
  </form>
</template>

<script setup>
import { watch } from "vue";
import { storeToRefs } from "pinia";
import useUserStore from "../../stores/user-store";
import TextInput from "./TextInput.vue";
import CheckboxInput from "./CheckboxInput.vue";
import DateOfBirthInput from "./DateOfBirthInput.vue";

const userStore = useUserStore();

const { registerErrors } = storeToRefs(userStore);

const emit = defineEmits(["submitRegisterForm"]);

const submitForm = () => {
  emit("submitRegisterForm");
};

const props = defineProps({
  weddingAddOns: {
    type: Boolean,
    default: false
  },
  hideCheckbox: {
    type: Boolean,
    default: false
  },
  user: {
    type: Object,
    default: () => {}
  },
  signupLocation: {
    type: String,
    required: true
  }
});

watch(
  () => props.weddingAddOns,
  newValue => {
    props.user.weddingAddOns = newValue;
  }
);

const triggerAnalyticsEvent = () => {
  if (props.user.newsletter) {
    analytics.track("Ticked Opt-In Box");
  } else {
    analytics.track("Unticked Opt-In Box");
  }
};
</script>
